import React, { Component } from "react";
import { logo, batuklogo } from "../../images";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/voucherAction";
class Navbar extends Component {
  state = {};

  logoutUser = () => {
    this.props.logoutUser(this.props.history);
  };

  render() {
    return (
      <nav>
        <div className="d-flex  justify-content-center align-items-center ">
          <div className=" mt-3  ">
            <img src={logo} alt="" srcSet="" height="60" />
          </div>
          <span className="mt-3">
            <img src={batuklogo} alt="" srcSet="" height="60" />
          </span>
        </div>
      </nav>
    );
  }
}

export default Navbar;
