import React, { Component } from "react";
import { Navbar } from "../common";
import { Chart, Table, Chartnew } from ".";
import jwt_decode from "jwt-decode";
import { submitVoucher } from "../../actions/voucherAction";
import { connect } from "react-redux";

class Layout extends Component {
  state = {
    userDetails: {},
    balance: "",
    total_mb: "",
    used_mb: "",
  };

  componentDidMount() {
    let token = localStorage.getItem("token");
    if (token === undefined || token === null) {
      this.props.history.push("/");
    } else {
      var decoded = jwt_decode(token);
      console.log(decoded);
      this.setState({
        userDetails: decoded,
        balance: decoded.balance,
        total_mb: decoded.bought_mb,
        used_mb: decoded.used_mb,
      });
    }
    let data = {
      name: decoded.name,
    };
    console.log(data);
    this.interval = setInterval(() => this.props.submitVoucher(data), 1200000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  logout = () => {
    localStorage.removeItem("token");
    this.props.history.push("/");
  };

  render() {
    const { userDetails, balance, total_mb, used_mb } = this.state;
    return (
      <div className="background holder">
        <Navbar />
        <div className="container border mt-5">
          <div className="d-flex justify-content-between ">
            <h6 className="mt-3">Your Personal Balance</h6>
            <button
              type="button"
              class="mt-3 btn btn-primary btn-sm"
              onClick={this.logout}
            >
              Logout
            </button>
          </div>

          <div className="d-flex justify-content-center  flex-column align-items-center">
            <div className="col-md-8 mt-5">
              <Table userDetails={userDetails} />
            </div>
            <div className="">
              {/* <Chart
                balance={((balance / total_mb) * 100).toFixed(2)}
                totalUsage={((used_mb / total_mb) * 100).toFixed(2)}
              /> */}
              <Chartnew
              // balance={balance}
              // totalUsage={used_mb}
              />
              {/* <div className="d-flex justify-content-center flex-column align-items-center">
                <strong className="pr-3">
                  Balance: {((balance / total_mb) * 100).toFixed(2)}%
                </strong>
                <strong>
                  Total Usage: {((used_mb / total_mb) * 100).toFixed(2)}%
                </strong>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { submitVoucher })(Layout);
