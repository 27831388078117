import React, { Component } from "react";
import { LoginInput } from "../common";
import { logo, batuklogo } from "../../images";
import { connect } from "react-redux";
import { submitVoucher } from "../../actions/voucherAction";
import Swal from "sweetalert2";

class Login extends Component {
  state = {
    name: "",
    voucher: {},
    voucherErrors: "",
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { name } = this.state;
    if (name === "") {
      Swal.fire("Error", "Voucher code should not be empty", "error");
    } else {
      let data = {
        name,
      };
      this.props.submitVoucher(data, this.props.history);
    }
  };
  render() {
    const { name } = this.state;
    return (
      <React.Fragment>
        <div className="login d-flex justify-content-center align-items-center">
          <div className="col-md-4 shadow-lg p-5 rounded-3 logincard">
            <div className="mb-5 d-flex align-items-center justify-content-center">
              <img src={logo} alt="Mawingu" srcset="" height="80" />
              <img src={batuklogo} alt="Mawingu" srcset="" height="80" />
            </div>
            <LoginInput
              placeholder="Enter voucher code to access your balance"
              name="name"
              value={name}
              onChange={this.onChange}
              type="password"
            />
            <div className="mt-4 d-grid gap-2">
              <button
                className="btn btn-primary btn-block"
                onClick={this.onSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  voucher: state.voucher.submitVoucherResponse,
  voucherErrors: state.voucher.submitVoucherErrors,
});

export default connect(mapStateToProps, { submitVoucher })(Login);
