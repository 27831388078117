import React, { useEffect } from "react";

import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "bootstrap/dist/js/bootstrap.min.js";

import { Login } from "./components/auth";
import { Layout } from "./components/main";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { getUserDetails } from "./actions/voucherAction";

function App() {
  // useEffect(() => {
  //   let token = localStorage.getItem("token");
  //   if (token === undefined || token === null) {
  //     // props.history.push("/");
  //     window.location.href = "/";
  //   } else {
  //     var decoded = jwt_decode(token);
  //     getUserDetails(decoded);
  //     // console.log(decoded);
  //   }
  // }, []);
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/status" component={Layout} />
      </Switch>
    </Router>
  );
}

export default App;
