import React, { Component } from "react";

class Table extends Component {
  render() {
    const { userDetails } = this.props;
    return (
      <div className="border-start border-end">
        <table className="table">
          <thead className="bg-primary text-light">
            <tr>
              <th>Voucher Code</th>
              <th>Total Bought</th>
              <th>Total Usage</th>
              <th>Units Remaining</th>
            </tr>
          </thead>
          <tbody>
            <tr className="fw-bold">
              {Object.keys(userDetails).length !== 0 && (
                <React.Fragment>
                  <td>{userDetails.name}</td>
                  <td>{`${userDetails.bought_mb.toFixed(2)} MB`}</td>
                  <td>{`${userDetails.used_mb.toFixed(2)} MB`}</td>
                  <td>{`${userDetails.balance.toFixed(2)} MB`}</td>
                </React.Fragment>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default Table;
