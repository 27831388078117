import {
  SUBMIT_VOUCHER_ERROR,
  SUBMIT_VOUCHER,
  GET_USER_DETAILS,
} from "../actions/types";

const initialState = {
  submitVoucherResponse: {},
  submitVoucherErrors: {},
  voucherDetails: {},
};
export default function (state = initialState, action) {
  switch (action.type) {
    case SUBMIT_VOUCHER:
      return {
        ...state,
        submitVoucherResponse: action.payload,
      };
    case SUBMIT_VOUCHER_ERROR:
      return {
        ...state,
        submitVoucherErrors: action.payload,
      };
    case GET_USER_DETAILS:
      return {
        ...state,
        voucherDetails: action.payload,
      };
    default:
      return state;
  }
}
