import React, { Component } from "react";
import Chart from "react-apexcharts";
import jwt_decode from "jwt-decode";

class Chartnew extends Component {
  state = {
    series: ["", ""],
    chartOptions: {
      //   legend: {
      //     labels: {
      //       fill: {
      //         colors: ["#9B51E0", "#FB8832"],
      //       },
      //     },
      //   },
      //   dataLabels: {
      //     style: {
      //       colors: ["#9B51E0", "#FB8832"],
      //     },
      //   },

      legend: {
        position: "top",
      },
      colors: ["#9B51E0", "#FB8832"],

      labels: ["Balance", "Data Usage"],
      fill: {
        colors: ["#9B51E0", "#FB8832"],
      },
    },
    // fill: {
    //   colors: ["#FF0000", "#E91E63"],
    // },
  };

  componentDidMount() {
    let token = localStorage.getItem("token");
    if (token === undefined || token === null) {
      this.props.history.push("/");
    } else {
      var decoded = jwt_decode(token);
      console.log(decoded);
      let balanceFixed = parseFloat(decoded.balance.toFixed(2));
      let usedFixed = parseFloat(decoded.used_mb.toFixed(2));
      console.log(balanceFixed);
      this.setState({
        userDetails: decoded,
        balance: decoded.balance,
        total_mb: decoded.bought_mb,
        used_mb: decoded.used_mb,
        series: [balanceFixed, usedFixed],
      });
    }
    let data = {
      name: decoded.name,
    };
    console.log(data);
    this.interval = setInterval(() => this.props.submitVoucher(data), 1200000);
  }

  render() {
    const { balance, totalUsage } = this.props;

    const { chartOptions, series, fill } = this.state;
    console.log(series);
    // const { balance, dataUsage}

    return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={chartOptions}
              series={series}
              type="pie"
              width={380}
              balance={balance}
              totalUsage={totalUsage}
              colors={fill}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Chartnew;
