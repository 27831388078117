import { axios } from "../utils";
import {
  GET_USER_DETAILS,
  SUBMIT_VOUCHER,
  SUBMIT_VOUCHER_ERROR,
} from "./types";
import Swal from "sweetalert2";
import sign from "jwt-encode";

const secret = "batuk";
//  submitting voucher code
export const submitVoucher = (voucherDetails, history) => (dispatch) => {
  let url = `https://batuk-backend.mawingunetworks.com/vouchers`;
  // let url = `http://posinventory.com.ng/categories`;
  axios
    .post(url, voucherDetails)
    .then((response) => {
      let jwt = sign(response.data, secret);

      localStorage.setItem("token", jwt);

      dispatch({
        type: SUBMIT_VOUCHER,
        payload: jwt,
      });

      history.push("/status");
    })
    .catch((error) => {
      console.log(error.response);
      if (error.response) {
        if (error.response.data.error) {
          Swal.fire("Error", error.response.data.error, "error");

          dispatch({
            type: SUBMIT_VOUCHER_ERROR,
            payload: error.response.data.error,
          });
        } else {
          Swal.fire("Error", "Oops! Something went wrong!", "error");
        }
      } else {
        dispatch({
          type: SUBMIT_VOUCHER_ERROR,
          payload: "Oops! Something went wrong!",
        });
      }
    });
};

//logout

export const logoutUser = (history) => {
  history.push("/");
};

// get user details
export const getUserDetails = (userDetails) => (dispatch) => {
  console.log(userDetails);
  dispatch({
    type: GET_USER_DETAILS,
    payload: userDetails,
  });
};
